
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { useNotification } from '@/composables'
//import UploadImage from '@/components/common/UploadImage.vue'
import ItemSKUList from './ItemSKUList.vue'
import orderProductService from '@/services/orderProductService'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import productDataService from '@/services/productDataService'

import { IProductWarehouse } from '@/interfaces/product-warehouse'
import { IOrderVariant, IOrderItem } from '@/interfaces/orders-warehouse'
import { imageUrl } from '@/utils/image'
import { Picture as IconPicture } from '@element-plus/icons'

export default defineComponent({
  name: 'AddSku',
  emits: ['ok', 'cancel'],
  props: {
    isShowAddSku: { type: Boolean, default: false },
  },
  components: { ItemSKUList, IconPicture },

  setup(props, context) {
    const { error, success, warning } = useNotification()

    const { getCatalogs } = userCatalogRepositories()
    const state = orderProductService.state
    const formAddSku = ref()
    const cancel = (): boolean => {
      context.emit('cancel', false)
      return false
    }
    const onChangeProduct = (productId: any): void => {
      state.product = state.products.find(
        (p: any) => p._id === productId
      ) as IProductWarehouse
      state.orderVariants = [...state.product.variants] as IOrderVariant[]
    }
    const callbackImage = (setData: string, files: Array<string>): void => {
      //state.addSku.design = files[0]
      console.log(`files`, files)
      // console.log(`configTheme`, configTheme, setData, files)
    }
    const onSubmit = (): void => {
      formAddSku.value.validate(async (valid: boolean): Promise<void> => {
        if (valid) {
          let messageError: Array<string> = []
          let items: Array<IOrderItem> = []
          state.orderVariants.forEach((e: IOrderVariant) => {
            if (e.quantity > 0) {
              const item: IOrderItem = {
                productId: state.orderItem.productId,
                variantId: e._id,
                _id: e.orderItemId,
                quantity: e.quantity,
                variantTitle: e.title,
                catalogCode: e.catalogVariantCode,
                sku: e.sku,
                catalogId: state.orderItem.catalogId,
              }
              const indexOfId = state.order?.items?.findIndex(
                i => i?.catalogCode === item?.catalogCode && i.sku === item.sku
              )
              //case update thì chỉ cập nhật lại data
              if (state.isSubmitedSku === orderProductService.ACTION.UPDATE) {
                if (indexOfId < 0) return error('Item not found')
                state.order.items[indexOfId] = item
              } else {
                //Check nếu bị trùng 1 item đã tồn tại
                if (indexOfId > 0) {
                  messageError.push(
                    `Item SKU: <strong>${item.sku}</strong>, name: <strong>${item.variantTitle}</strong>, catalog code: <strong>${item.catalogCode}</strong> exists </br>`
                  )
                }
                items.push(item)
              }
            }
          })
          if (state.isSubmitedSku === orderProductService.ACTION.CREATE) {
            if (messageError.length > 0) {
              return warning(messageError.join('\n\r\n\n'))
            }
            state.order.items = state?.order?.items
              ? [...state?.order?.items, ...items]
              : [...items]
          }
          state.isShowAddSku = false
        }
      })
    }
    const getCatalog = async (query?: string) => {
      const info = JSON.parse(localStorage.getItem('info') || '')
      const params = { ...state.params }
      params.basecost = info?.sellerTeam?.basecost
      if (query) params.search = query
      let res = await getCatalogs(params)
      state.catalogs = res as any[]
    }
    const getProducts = async (query?: string) => {
      const params = { ...state.params }
      if (query) params.search = query
      console.log(state.params)
      let res = await productDataService.getAll(params)
      state.products = res.status === 200 ? res.data : []
    }

    onMounted(() => {
      getCatalog()
      getProducts()
    })

    watch(props, val => {
      state.isShowAddSku = val.isShowAddSku
    })

    return {
      cancel,
      state,
      callbackImage,
      formAddSku,
      onSubmit,
      onChangeProduct,
      getProducts,
      getCatalog,
      imageUrl,
      orderProductService,
    }
  },
})
