
import OrderDataService from '@/services/orderProductService'
import ImageViewDialog from './ImageViewDialog.vue'
import { defineComponent, ref, onMounted } from 'vue'
import { useNotification } from '@/composables'
import { IOrderItem, IOrderVariant } from '@/interfaces/orders-warehouse'
import productDataService from '@/services/productDataService'
import { Delete, Edit } from '@element-plus/icons'
import { ElMessageBox } from 'element-plus'

export default defineComponent({
  components: { ImageViewDialog, Delete, Edit },
  setup() {
    const imageViewDialog = ref<InstanceType<typeof ImageViewDialog>>()
    const state = OrderDataService.state
    const { error, success } = useNotification()

    const Preview = (path: string): void => {
      path ? imageViewDialog.value?.view(path) : error('URL not found')
    }
    const handleEdit = async (item: IOrderItem) => {
      state.isShowAddSku = true
      state.isSubmitedSku = OrderDataService.ACTION.UPDATE
      const res = await productDataService.getById(item.productId)
      if (res.status === 200) state.product = res.data
      else return error(res.data)
      state.orderVariants = [
        {
          orderItemId: item._id,
          quantity: item.quantity,
          title: item.variantTitle,
          catalogVariantCode: item.catalogCode,
          sku: item.sku,
        },
      ] as IOrderVariant[]
      state.orderItem = item
    }
    const handleDelete = async (item: IOrderItem) => {
      ElMessageBox.confirm(`Data will be deleted when clicking Save`).then(
        async () => {
          const items = [...state.order.items]
          state.order.items = items.filter(i => i.sku !== item.sku)
        }
      )
    }
    return {
      state,
      imageViewDialog,
      Preview,
      handleEdit,
      handleDelete,
      OrderDataService,
    }
  },
})
