
import { defineComponent, ref, computed, onMounted, onBeforeMount } from 'vue'
import { useNotification } from '@/composables'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import {
  IOrderWarehouse,
  IServiceResponse,
  IOrderItem,
  IOrderVariant,
  FileItem,
} from '@/interfaces/orders-warehouse'
import ItemList from './components/ItemList.vue'
import AddSku from './components/AddSku.vue'
import OrderDataService from '@/services/orderProductService'
import { useRoute, useRouter } from 'vue-router'
import warehouseDataService from '@/services/warehouseDataService'
import { IProductWarehouse } from '@/interfaces/product-warehouse'
import { Upload, Warning } from '@element-plus/icons'
import csv from 'csvtojson'

export default defineComponent({
  name: 'Add',
  components: {
    sectionLayoutContent,
    ItemList,
    AddSku,
    Upload,
    Warning,
  },
  props: {},
  setup() {
    const breadcrumbs = ['Orders', 'Add']
    const state = OrderDataService.state
    const { error, success } = useNotification()
    const route = useRoute()
    const router = useRouter()
    const formOrder = ref()
    const fileList = ref<FileItem>()

    const content = computed(() => {
      return { breadcrumbs }
    })
    const handleAddSkuCancel = (): void => {
      state.isShowAddSku = false
    }
    const showAddSku = (): void => {
      state.isSubmitedSku = OrderDataService.ACTION.CREATE
      state.orderVariants = [] as IOrderVariant[]
      state.orderItem = {} as IOrderItem
      state.product = {} as IProductWarehouse
      state.isShowAddSku = true
    }
    const getWarehouses = async (query?: string) => {
      const params = { ...state.params }
      if (query) params.search = query
      let res = await warehouseDataService.getAll(params)
      state.warehouses = res.data
    }
    onBeforeMount(() => {
      setAction()
      getReferenceId()
      resetForm()
    })
    onMounted(async () => {
      if (state.isSubmited === OrderDataService.ACTION.UPDATE) {
        const id = route.params?.id
        const data: IServiceResponse = await OrderDataService.getById(id)

        state.order = { ...data.data }
      }
      getWarehouses()
    })
    //
    const onChangeFiles = async (file: FileItem) => {
      fileList.value = file
    }
    const onSubmitImport = async (): Promise<void> => {
      try {
        let reader: any = new FileReader()
        if (!fileList.value) {
          return error('File not found. Check the file and try again')
        }
        reader.readAsBinaryString(fileList.value?.raw)
        reader.onload = (evt: any) => {
          csv()
            .fromString(evt.target.result)
            .subscribe((jsonObj: IOrderVariant[]) => {
              jsonObj.forEach((e: IOrderVariant) => {
                if (e.quantity > 0) {
                  const item: IOrderItem = {
                    productId: state.orderItem.productId,
                    variantId: e._id,
                    _id: e.orderItemId,
                    quantity: e.quantity,
                    variantTitle: e.title,
                    catalogCode: e.catalogVariantCode,
                    sku: e.sku,
                    catalogId: state.orderItem.catalogId,
                  }
                  const indexOfId = state.order.items.findIndex(
                    i => i._id === item._id
                  )
                  indexOfId < 0
                    ? state.order.items.push(item)
                    : (state.order.items[indexOfId] = item)
                }
              })
            })
        }
      } catch (err: any) {
        error(err)
      }
    }
    const onSubmit = (): void => {
      formOrder.value.validate(async (valid: boolean): Promise<void> => {
        if (valid) {
          const { data, status } =
            state.isSubmited === OrderDataService.ACTION.CREATE
              ? await OrderDataService.create(state.order)
              : await OrderDataService.update(state.order)
          if (status === 201 || status === 200) {
            success(data.message)
          }
        }
      })
    }

    const setAction = (): void => {
      state.isSubmited =
        route.name === 'seller.orders-warehouse.update'
          ? OrderDataService.ACTION.UPDATE
          : OrderDataService.ACTION.CREATE
    }
    const resetForm = (): void => {
      if (state.isSubmited === OrderDataService.ACTION.CREATE) {
        state.order = {} as IOrderWarehouse
        state.orderItem = {} as IOrderItem
        state.orderVariants = [] as IOrderVariant[]
      }
    }
    //referenceId chính bằng ordername được sinh ra bằng cách gọi API
    const getReferenceId = async (): Promise<void> => {
      if (state.isSubmited === OrderDataService.ACTION.CREATE) {
        const res = await OrderDataService.getOrderName()
        if (res.status === 200)
          state.order.orderName = state.order.referenceId1 = res.data
      }
    }
    const onCancel = async () => {
      router.push({
        name: 'seller.orders-warehouse',
        params: {},
      })
    }

    return {
      state,
      content,
      handleAddSkuCancel,
      showAddSku,
      onSubmit,
      formOrder,
      getWarehouses,
      onCancel,
      onChangeFiles,
      onSubmitImport,
      ACTION: OrderDataService.ACTION,
    }
  },
})
