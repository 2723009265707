<template>
  <div>
    <el-table
      :data="state.orderVariants"
      v-loading="state.isLoading"
      :lazy="state.isLoading"
      style="width: 100%"
      max-height="300"
    >
      <el-table-column prop="title" label="Variants" width="180" />
      <el-table-column
        prop="catalogVariantCode"
        label="Variant Code"
        width="180"
      />
      <el-table-column prop="sku" label="SKU Variant" />
      <el-table-column prop="quantity" label="Quantity">
        <template v-slot#default="scope">
          <el-input-number
            v-model="scope.row.quantity"
            :min="0"
            :max="1000000"
            size="small"
          ></el-input-number>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import OrderDataService from '@/services/orderProductService'

export default {
  name: 'ItemSKUList',

  setup() {
    const state = OrderDataService.state

    return {
      state,
    }
  },
}
</script>
