import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialog) = $event)),
    title: "Preview image",
    width: "30%",
    onClose: _ctx.close,
    onVisibleChange: _ctx.close,
    center: "",
    top: "1vh",
    "append-to-body": ""
  }, {
    default: _withCtx(() => [
      (!_ctx.imgView.includes('http'))
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.imageUrl(_ctx.imgView, true),
            alt: ""
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: `${_ctx.imgView}`,
            alt: ""
          }, null, 8, _hoisted_2))
    ]),
    _: 1
  }, 8, ["modelValue", "onClose", "onVisibleChange"]))
}